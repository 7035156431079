<template>
        <div style="height: 776px; position: relative">
          <v-app-bar
              absolute
              flat
              shrink-on-scroll
              class="header__wrapper"
              src="../assets/tiomothy-swope-zwe--GYIZtc-unsplash 2.jpg"
            >
            <template v-slot:img="{ props }">
              <v-img
                  v-bind="props"
                  height="776"
                  gradient="to top right, rgba(0,0,0,.3), rgba(0,0,0,.4)"
              ></v-img>
            </template>
            <div class="content d-flex justify-space-between" style="max-width: 1440px; gap: 40px;" >

              <!--    <v-app-bar-nav-icon-->
              <!--        @click.stop="$emit('toggle-drawer')"-->
              <!--        style="position: absolute"-->
              <!--        v-if="isMobile"-->
              <!--    />-->
              <v-app-bar-nav-icon>
                <router-link to="/">
                  <v-img
                      class="ml-4"
                      :src="require('../assets/logo.svg')"
                      contain
                      :width="isMobile ? '73' : '95'"
                  />
                </router-link>
              </v-app-bar-nav-icon>

              <div v-if="!isMobile" class="d-flex  justify-between">
                <v-btn
                    v-for="link in links"
                    :key="link.url"
                    text
                    link
                    rounded
                    class="text-none links px-1"
                    :href="`#${link.url}`"
                >
                  <span class="heading">{{ link.title }}</span>
                </v-btn>
              </div>
              <div class="d-flex flex-wrap flex-column align-center">
                <div class="contact-block d-flex flex-column align-center">
                  <ul>
                    <li class="d-flex flex-column">
                        <a class="d-flex gap-2" href="tel:77172570106">
                           <v-icon color="#FEFEFE" class="contact-block__icon">mdi-phone-outline</v-icon>
                          <span>+7 717 257 01 06</span>
                        </a>
                        <a class="d-flex gap-2" href="tel:77077068220">
                          <v-icon color="#FEFEFE" class="contact-block__icon">mdi-phone-outline</v-icon>
                          <span>+7 707 706 82 20</span>
                        </a>
                    </li>
                    <li>
                        <a class="d-flex gap-2" href="mailto:info@qsm.kz">
                          <v-icon color="#FEFEFE" class="contact-block__icon">mdi-email-outline</v-icon>
                        <span>info@qsm.kz</span>
                      </a>
                    </li>
                  </ul>
                </div>

              </div>

            </div>




            <!--    <v-col align="center" class="">-->
            <!--      <v-row align="center" class="app-bar mt-6">-->
            <!--        <v-col-->
            <!--            :align="isMobile ? 'center' : 'start'"-->
            <!--            class=""-->
            <!--        >-->


      <!--              </v-col>-->
      <!--              <v-col v-if="!isMobile">-->
      <!--                <v-btn-->
      <!--                    v-for="link in links"-->
      <!--                    :key="link.url"-->
      <!--                    text-->
      <!--                    link-->
      <!--                    rounded-->
      <!--                    class="text-none white&#45;&#45;text links"-->
      <!--                    :href="link.url"-->
      <!--                >-->
      <!--                  {{ link.title }}-->
      <!--                </v-btn>-->
      <!--              </v-col>-->
      <!--            </v-row>-->
      <!--          </v-col>-->

          </v-app-bar>
          <div class="content complex" style="max-width: 1440px; width: 100%; ">
            <span class="complex__bold">Комплекс услуг</span><br/>
            <span class="complex__bold">строительно-монтажных работ </span><br/>
            <span class="complex__normal">по Казахстану</span>
          </div>
  </div>

</template>

<script>
export default {
  name: "TopBar",
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
  methods: {
    toggleNav() {
      this.$emit('toggle-nav');
    },
  },
  data: () => ({
    links: [
      {
        title: 'О нас',
        url: 'about'
      },
      {
        title: 'Услуги',
        url: 'services'
      },
      {
        title: 'Преимущества',
        url: 'benefits'
      },
      {
        title: 'Проекты',
        url: 'projects'
      },
      {
        title: 'Партнеры',
        url: 'partners'
      },
      {
        title: 'Лицензии',
        url: 'license'
      },

      {
        title: 'Отзывы',
        url: 'info'
      },
    ],
  }),
}
</script>

<style scoped lang="scss">
.app-bar {
  max-width: 1440px;
}
.header__wrapper {
  width: 100%;
  margin: 0 auto;
  height: 776px !important;
  z-index: 3;
}
::v-deep .v-toolbar__content {
  max-width: 1440px;
  margin: auto;
}
.content {
  width: 1110px;
  margin: 0 auto;
  padding-top: 20px;
}
.links {
  font-size: 16px !important;
  font-weight: 500;
}
.heading {
  color: #FEFEFE;
  font-size: 16px;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
}
.contact-block {
  color: #ffffff;
  ul {
    list-style-type: none;
    padding-left: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
    li{
      display: flex;
    }
    li span {
      white-space: nowrap;
      color: #FEFEFE;
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      @media (max-width: 1200px) {
        font-size: 14px;
      }
    }
  }
  &__icon {
    font-size: 20px;
  }
}
a{
  text-decoration: none;
}
.complex {
  position: absolute;
  top:37%;
  left: 17%;
  z-index: 9999;
  @media (max-width: 1000px) {
    font-size: 32px;
    right: 0px;
    left: 0px;
    padding: 20px;
    width: 100%;
  }
  color: #FEFEFE;
  font-size: 44px;
  &__bold {
    font-weight: 700;
  }
  &__normal {
    font-weight: 400;
  }
}
.gap-2{
  gap: 8px;
}
</style>
