<template>
  <v-carousel v-model="slider" delimiter-icon="">
    <template v-slot:next="{ on, attrs }" >
      <svg v-bind="attrs" v-on="on" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 12.5C1.5 15.2848 2.60625 17.9555 4.57538 19.9246C6.54451 21.8938 9.21523 23 12 23C14.7848 23 17.4555 21.8938 19.4246 19.9246C21.3938 17.9555 22.5 15.2848 22.5 12.5C22.5 9.71523 21.3938 7.04451 19.4246 5.07538C17.4555 3.10625 14.7848 2 12 2C9.21523 2 6.54451 3.10625 4.57538 5.07538C2.60625 7.04451 1.5 9.71523 1.5 12.5ZM24 12.5C24 15.6826 22.7357 18.7348 20.4853 20.9853C18.2348 23.2357 15.1826 24.5 12 24.5C8.8174 24.5 5.76516 23.2357 3.51472 20.9853C1.26428 18.7348 0 15.6826 0 12.5C0 9.3174 1.26428 6.26516 3.51472 4.01472C5.76516 1.76428 8.8174 0.5 12 0.5C15.1826 0.5 18.2348 1.76428 20.4853 4.01472C22.7357 6.26516 24 9.3174 24 12.5ZM6.75 11.75C6.55109 11.75 6.36032 11.829 6.21967 11.9697C6.07902 12.1103 6 12.3011 6 12.5C6 12.6989 6.07902 12.8897 6.21967 13.0303C6.36032 13.171 6.55109 13.25 6.75 13.25L15.4395 13.25L12.219 16.469C12.1493 16.5387 12.094 16.6215 12.0562 16.7126C12.0185 16.8037 11.9991 16.9014 11.9991 17C11.9991 17.0986 12.0185 17.1963 12.0562 17.2874C12.094 17.3785 12.1493 17.4613 12.219 17.531C12.2887 17.6007 12.3715 17.656 12.4626 17.6938C12.5537 17.7315 12.6514 17.7509 12.75 17.7509C12.8486 17.7509 12.9463 17.7315 13.0374 17.6938C13.1285 17.656 13.2113 17.6007 13.281 17.531L17.781 13.031C17.8508 12.9613 17.9063 12.8786 17.9441 12.7874C17.9819 12.6963 18.0013 12.5987 18.0013 12.5C18.0013 12.4013 17.9819 12.3037 17.9441 12.2125C17.9063 12.1214 17.8508 12.0387 17.781 11.969L13.281 7.469C13.2113 7.39927 13.1285 7.34395 13.0374 7.30621C12.9463 7.26848 12.8486 7.24905 12.75 7.24905C12.6514 7.24905 12.5537 7.26848 12.4626 7.30621C12.3715 7.34395 12.2887 7.39927 12.219 7.469C12.1493 7.53873 12.094 7.62152 12.0562 7.71262C12.0185 7.80373 11.9991 7.90138 11.9991 8C11.9991 8.09862 12.0185 8.19627 12.0562 8.28738C12.094 8.37848 12.1493 8.46127 12.219 8.531L15.4395 11.75L6.75 11.75Z" fill="#FEFEFE"/>
      </svg>
    </template>
    <template v-slot:prev="{ on, attrs }">
      <svg v-bind="attrs" v-on="on" width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 12.5C22.5 9.71523 21.3938 7.04451 19.4246 5.07538C17.4555 3.10625 14.7848 2 12 2C9.21523 2 6.54451 3.10625 4.57538 5.07538C2.60625 7.04451 1.5 9.71523 1.5 12.5C1.5 15.2848 2.60625 17.9555 4.57538 19.9246C6.54451 21.8938 9.21523 23 12 23C14.7848 23 17.4555 21.8938 19.4246 19.9246C21.3938 17.9555 22.5 15.2848 22.5 12.5ZM1.04907e-06 12.5C1.3273e-06 9.3174 1.26428 6.26515 3.51472 4.01472C5.76516 1.76428 8.8174 0.499999 12 0.499999C15.1826 0.499999 18.2348 1.76428 20.4853 4.01472C22.7357 6.26515 24 9.3174 24 12.5C24 15.6826 22.7357 18.7348 20.4853 20.9853C18.2348 23.2357 15.1826 24.5 12 24.5C8.8174 24.5 5.76515 23.2357 3.51472 20.9853C1.26428 18.7348 7.70842e-07 15.6826 1.04907e-06 12.5ZM17.25 13.25C17.4489 13.25 17.6397 13.171 17.7803 13.0303C17.921 12.8897 18 12.6989 18 12.5C18 12.3011 17.921 12.1103 17.7803 11.9697C17.6397 11.829 17.4489 11.75 17.25 11.75L8.5605 11.75L11.781 8.531C11.8507 8.46127 11.906 8.37848 11.9438 8.28738C11.9815 8.19627 12.0009 8.09861 12.0009 8C12.0009 7.90138 11.9815 7.80373 11.9438 7.71262C11.906 7.62151 11.8507 7.53873 11.781 7.469C11.7113 7.39927 11.6285 7.34395 11.5374 7.30621C11.4463 7.26848 11.3486 7.24905 11.25 7.24905C11.1514 7.24905 11.0537 7.26848 10.9626 7.30621C10.8715 7.34395 10.7887 7.39927 10.719 7.469L6.219 11.969C6.14916 12.0387 6.09374 12.1214 6.05593 12.2125C6.01812 12.3037 5.99866 12.4013 5.99866 12.5C5.99866 12.5986 6.01812 12.6963 6.05593 12.7874C6.09374 12.8786 6.14916 12.9613 6.219 13.031L10.719 17.531C10.7887 17.6007 10.8715 17.656 10.9626 17.6938C11.0537 17.7315 11.1514 17.7509 11.25 17.7509C11.3486 17.7509 11.4463 17.7315 11.5374 17.6938C11.6285 17.656 11.7113 17.6007 11.781 17.531C11.8507 17.4613 11.906 17.3785 11.9438 17.2874C11.9815 17.1963 12.0009 17.0986 12.0009 17C12.0009 16.9014 11.9815 16.8037 11.9438 16.7126C11.906 16.6215 11.8507 16.5387 11.781 16.469L8.5605 13.25L17.25 13.25Z" fill="#FEFEFE"/>
      </svg>
    </template>
    <v-carousel-item
        v-for="(slide, i) in slides"
        :key="i"
        class="slider"
    >
      <v-sheet
          color="black"
          height="100%"
          width="100%"
          tileSlider.vue
      >
        <v-container
            fluid
            class="pa-15"
        >
          <div
              class="slide"
          >
            <div class="slide__left">
              <div class="slide__left-current">
                <v-img :src="generateUrl(activeImage)"></v-img>
              </div>
              <div class="slide__left-images">
                <v-img
                    v-for="(image, index) in slide.images" :key="index"
                    :src="generateUrl(image)"
                    height="60px"
                    width="60px"
                    contain
                    :class="[{'active': image === activeImage}, 'slide__left-images__item']"
                    @click="activeImage = image"
                >

                </v-img>
              </div>

            </div>
            <v-col class="slide__right" align-self="center">
              <div class="slide__right-title">
                <span>{{slide.title}}</span>
              </div>
              <div  class="slide__right-customer">
                <span><b>Заказчик: </b>{{slide.customer}}</span>
              </div>
              <div  class="slide__right-works">
                <span><b>Выполненные работы:</b></span>
                <div class="slide__right-works__list" v-for="(work, wIndex) in slide.works"
                  :key="wIndex"
                >
                  <span>
                    {{work}}
                  </span>
                </div>
              </div>
            </v-col>
          </div>
        </v-container>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>

</template>

<script>
export default {
  name: "Slider",
  data() {
    return {
      slider: 0,
      slides: [
        {
        title: 'Строительство магистральных и внутриквартальных' +
            'инженерных сетей мкр-н «Достык" в  г. Павлодар',
        customer: 'ГУ «Отдел строительства города Павлодар»',
        works: [
            'Прокладка кабельной линии 10кв из кабеля марки АПВПУГ 3х400/70, протяженностью 22,5 км, для питания двух распределительных подстанций 10/0,4 кв',
            'Прокладка кабельной линии 10кв из кабеля марки АПВПУГ 3х240, протяженностью 4,74 км, для питания шести блочно-модульных трансформаторных подстанции КТПБ 2х1000ква',
            'Поставка и монтаж двух распределительных подстанции 10/0,4 кв и шести трансформаторных подстанции КТПБ 2х1000ква'
        ],
        images: [
          'image 4.png',
          'image 4.png',
          'image 4.png',
          'image 4.png',
          'image 4.png',
          'image 4.png',
          'image 4.png',
        ],
      },
        {
          title: 'Строительство магистральных и внутриквартальных' +
              'инженерных сетей мкр-н «Достык" в  г. Павлодар',
          customer: 'ГУ «Отдел строительства города Павлодар»',
          works: [
            'Прокладка кабельной линии 10кв из кабеля марки АПВПУГ 3х400/70, протяженностью 22,5 км, для питания двух распределительных подстанций 10/0,4 кв',
            'Прокладка кабельной линии 10кв из кабеля марки АПВПУГ 3х240, протяженностью 4,74 км, для питания шести блочно-модульных трансформаторных подстанции КТПБ 2х1000ква',
            'Поставка и монтаж двух распределительных подстанции 10/0,4 кв и шести трансформаторных подстанции КТПБ 2х1000ква'
          ],
          images: [
            'image 4.png',
            'image 4.png',
            'image 4.png',
            'image 4.png',
          ],
        }],
      activeImage: null,

    }
  },
  created() {
    this.activeImage = this.slides[this.slider].images[0]
  },
  methods: {
    generateUrl(url) {
      return require('../assets/slider/' + url)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-window {
  overflow: visible;
  &__container {
    .container {
      padding: 0 32px !important;
    }
  }
  &__prev {
    background: none;
    margin: 0;
  }
}
.v-carousel {
  overflow: visible;
}
::v-deep .v-carousel__controls {
  &__item {
    background: #D8B983;
    width: 7px;
    height: 7px;
  }

}
.slider {
  .slide {
    display: flex;
    width: 100%;
    &__left {
      width: 50%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-current {
        height: calc(100% - 60px);
      }
      &-images {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        overflow: hidden;
        &__item {
          opacity: 0.4;
        }
        .active {
          opacity: 1;
        }
      }
    }

    &__right {
      position: relative;
      width: 50%;
      display: flex;
      flex-direction: column;
      font-style: normal;

      color: #FEFEFE;
      &-title {
        span {
          font-size: 20px;
          line-height: 24px;
          font-weight: 700;
        }
        padding-bottom: 24px;
      }
      &-customer {
        font-size: 16px;
        line-height: 20px;
        b {
          font-weight: 500;
        }
        span {

          font-weight: 300;
        }
        padding-bottom: 12px;
      }
      &-works {
        span {
          font-size: 16px;
          line-height: 20px;
          font-weight: 300;
        }
        &__list {
          position: relative;
          padding-left: 20px;
          margin-top: 12px;
          &:before {
            background: linear-gradient(0deg, #D8B983, #D8B983),
            linear-gradient(0deg, #D8B983, #D8B983),
            linear-gradient(0deg, #D8B983, #D8B983),
            linear-gradient(0deg, #D8B983, #D8B983),
            linear-gradient(0deg, #D8B983, #D8B983),
            linear-gradient(0deg, #D8B983, #D8B983), #D8B983;
            display: block;
            position: absolute;
            content: "";
            width: 8px;
            height: 8px;
            left: 0;
            top: 10px;
          }

        }
        padding-bottom: 12px;
      }
    }
  }
}
</style>
