<template>
        <v-container fluid class="ma-0 pt-0 flex-column flex-nowrap" :class="!isMobile && 'fill-height'">
          <v-row id="about" class="home-content mx-auto d-flex">
            <v-col>
              <div class="mb-10">
                <span class="h2">
                  О
                  <span class="h2__sign">нас</span>
                </span>
              </div>
              <div>
                <span class="white--text">
                  Наша компания уже более 10 лет профессионально осуществляет свою деятельность в сфере строительства на рынке
                  Казахстана. Мы предоставляем строительные услуги высочайшего качества по доступным ценам.
                  <br /><br />
                  Наша цель создать комфорт для Заказчика и высокое качество строящегося объекта.
                  <br /><br />
                  Любой Ваш проект объекта, комплекса или сооружения мы успешно воплотим в жизнь! Для нас не существует
                  невыполнимых задач!
                </span>
              </div>
              <div class="see-more mt-6">
                <span>Подробнее </span><v-icon color="#D8B983">mdi-chevron-down</v-icon>
              </div>
            </v-col>
            <v-col v-if="!isMobile" class="text-right">
              <v-img alt="Доступно в Appstore" class="shrink mr-2" :src="require('../assets/image 4.jpg')"
                transition="scale-transition" width="445" style="float: right; overflow: visible;">
                <div class="square"></div>
              </v-img>
            </v-col>
          </v-row>
          <v-row id="services" class="home-content mx-auto d-flex">
            <v-col>
              <div class="mb-10">
                <span class="h2">
                  Наши
                  <span class="h2__sign">услуги</span>
                </span>
              </div>
              <div class="bordered-block">
                <v-row>
                  <v-col>
                    <ul>
                      <li v-for="(item, index) in services" :key="item.title" class="d-flex align-content-center align-center"
                        @click="activate(index)" :class="{ active: active_el === index }">
                        <v-img alt="Доступно в Appstore" class="shrink mr-2" :src="require(`../assets/service/${item.icon}`)"
                          transition="scale-transition" width="50" min-width="50" contain>
                        </v-img>
                        <span class="ml-2">{{ item.title }}</span>
                      </li>
                    </ul>
                  </v-col>
                  <v-col>
                    <div class="text-right white--text pt-6">
                      {{ services[active_el].text }}
                    </div>
                  </v-col>
                </v-row>

              </div>
            </v-col>
          </v-row>
          <v-row id="benefits" class="home-content mx-auto d-flex">
            <v-col>
              <div class="mb-10">
                <span class="h2">
                  Наши
                  <span class="h2__sign">преимущества</span>
                </span>
              </div>
              <div class="d-flex flex-wrap justify-space-between align-content-stretch">
                <div class="third">
                  <div class="bordered-block bordered-block__small">
                    <v-img class="shrink mr-2 mb-5 top-icon" :src="require('../assets/quality.svg')"
                      transition="scale-transition" width="60" min-width="60" contain />
                    <span class="bordered-block__header">Гарантированное качество</span>
                    <span class="white--text">Мы никогда не экономим на качестве и работаем только с самыми надежными,
                      проверенными за много лет поставщиками материалов. </span>
                  </div>
                </div>
                <div class="third">

                  <div class="bordered-block bordered-block__small">
                    <v-img class="shrink mr-2 mb-5 top-icon" :src="require('../assets/hand-shake.png')"
                      transition="scale-transition" width="40" min-width="40" contain />
                    <span class="bordered-block__header">Партнерство</span>
                    <span class="white--text">При работе с партнерами мы учитываем специфику их деятельности, условия в регионе
                      и стремимся выработать наиболее взаимовыгодные формы создания совместного бизнеса</span>
                  </div>
                </div>
                <div class="third">

                  <div class="bordered-block bordered-block__small">
                    <v-img class="shrink mr-2 mb-5 top-icon" :src="require('../assets/opacity.svg')"
                      transition="scale-transition" width="40" min-width="40" contain />
                    <span class="bordered-block__header">Прозрачность</span>
                    <span class="white--text">Мы полностью расписываем весь спектр проводимых работ и их стоимость.
                      Названная в начале строительства сумма - это фиксированная цена, которая не будет изменена нами, если вы
                      сами не захотите пересмотреть смету</span>
                  </div>
                </div>
                <div class="third">
                  <div class="bordered-block bordered-block__small">
                    <v-img class="shrink mr-2 mb-5 top-icon" :src="require('../assets/time.svg')" transition="scale-transition"
                      width="40" min-width="40" contain />
                    <span class="bordered-block__header">Высокая скорость</span>
                    <span class="white--text">В результате хорошо организованной работы и накопленным годами профессионализма
                      наша компания производит строительные работы в самые кратчайшие сроки</span>
                  </div>
                </div>
                <div class="third">

                  <div class="bordered-block bordered-block__small">
                    <v-img class="shrink mr-2 mb-5 top-icon" :src="require('../assets/label.svg')" transition="scale-transition"
                      width="40" min-width="40" contain />
                    <span class="bordered-block__header">Оптимальные цены</span>
                    <span class="white--text">Вам никогда не придется переплачивать. Все цены на услуги конкурентоспособны и
                      полностью соответствуют их качеству</span>
                  </div>
                </div>
                <div class="third">

                  <div class="bordered-block bordered-block__small">
                    <v-img class="shrink mr-2 mb-5 top-icon" :src="require('../assets/file.svg')" transition="scale-transition"
                      width="40" min-width="40" contain />
                    <span class="bordered-block__header">Лицензия на работы</span>
                    <span class="white--text">Строительство - наша профессиональная деятельность подтверждена лицензиями и
                      сертификатами.
                      Все работы осуществляется нами в полном соответствии со стандартами и нормами</span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row id="projects" class="home-content mx-auto d-flex">
            <v-col cols="12">
              <div class="mb-10">
                <span class="h2">
                  Наши
                  <span class="h2__sign">проекты</span>
                </span>
              </div>
            </v-col>
            <v-col cols="12">
              <Slider />
            </v-col>
          </v-row>
          <v-row id="partners" class="home-content mx-auto d-flex">
            <div class="mb-10">
              <span class="h2">
                Наши
                <span class="h2__sign">партнеры</span>
              </span>
            </div>
            <div class="d-flex justify-space-between align-center flex-wrap" style="width: 100%;">
              <div v-for="item in partners" :key="item.id" class="partner-block">
                <v-img class="shrink mr-2 mb-5" :src="require(`../assets/partners/${item.pic}`)" transition="scale-transition"
                  max-height="80" width="150px" contain />
              </div>
            </div>
            <div style="width: 100%" class="see-more text-center">
              <v-icon color="#D8B983" style="font-size: 14px">mdi-plus</v-icon>
              <span>
                другие
              </span>
            </div>
          </v-row>
          <v-row id="license" class="home-content mx-auto d-flex">
            <div class="mb-10">
              <span class="h2">
                <span class="h2__sign">Лицензии и сертификаты</span>
                компании
              </span>
            </div>
            <div class="d-flex flex-wrap justify-space-around align-content-stretch">
              <div v-for="i in 5" :key="i" class="third">
                <v-img class="shrink mr-2 mb-5" :src="require(`../assets/license.png`)" transition="scale-transition"
                  max-width="340" contain />
              </div>
            </div>
          </v-row>
          <v-row id="info" class="home-content mx-auto d-flex">
            <v-col cols="12">
              <div class="mb-10">
                <span class="h2">
                  <span class="h2__sign">Отзывы</span>
                  о нас
                </span>
              </div>
            </v-col>
            <v-col cols="12">
              <div style="width: 100%">
                <carousel :perPageCustom="[[768, 1], [1024, 3]]" :paginationEnabled="false" navigationEnabled :centerMode="true"
                  :navigationNextLabel="next" :navigationPrevLabel="prev">
                  <slide>
                    <v-img class="shrink mr-2 mb-5" :src="require(`../assets/license.png`)" transition="scale-transition"
                      max-width="340" contain />
                  </slide>
                  <slide>
                    <v-img class="shrink mr-2 mb-5" :src="require(`../assets/license.png`)" transition="scale-transition"
                      max-width="340" contain />
                  </slide>
                  <slide>
                    <v-img class="shrink mr-2 mb-5" :src="require(`../assets/license.png`)" transition="scale-transition"
                      max-width="340" contain />
                  </slide>
                  <slide>
                    <v-img class="shrink mr-2 mb-5" :src="require(`../assets/license.png`)" transition="scale-transition"
                      max-width="340" contain />
                  </slide>
                </carousel>
              </div>

            </v-col>

          </v-row>
          <v-row id="contacts" class="home-content mx-auto d-flex">
            <div class="mb-10">
              <span class="h2">
                Наши
                <span class="h2__sign">контакты</span>
              </span>
            </div>
            <div id="map" style="width:1440px; height:600px; position: relative">
              <div class="contact-block">
                <ul>
                  <li class="d-flex flex-column ">
                    <a class="d-flex gap-2 text-decoration-none" href="tel:77172570106">
                      <v-icon color="#D8B983" class="contact-block__icon">mdi-phone-outline</v-icon>
                      <span>+7 717 257 01 06</span>
                    </a>
                    <a class="d-flex gap-2 text-decoration-none" href="tel:77077068220">
                      <v-icon color="#D8B983" class="contact-block__icon">mdi-phone-outline</v-icon>
                      <span>+7 707 706 82 20</span>
                    </a>
                  </li>
                  <li>
                    <a class="d-flex gap-2 text-decoration-none" href="mailto:info@qsm.kz">
                      <v-icon color="#D8B983" class="contact-block__icon">mdi-email-outline</v-icon>
                      <span>info@qsm.kz</span>
                    </a>
                  </li>
                  <li>
                    <v-icon color="#D8B983" class="contact-block__icon">mdi-map-marker-outline</v-icon>
                    <span>ул Достык, 1</span>
                  </li>
                  <li>
                    <a class="d-flex gap-2 text-decoration-none" href="https://wa.me/77077068220" target="_blank">
                      <v-icon color="#D8B983" class="contact-block__icon">mdi-whatsapp</v-icon>
                      <span>+7 707 706 82 20</span>
                    </a>
            </li>
          </ul>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>

import { appstore_link, google_play_link } from "@/helper";
import Slider from "./Slider";
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: "Home",
  components: {
    Slider,
    Carousel,
    Slide
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isIPhone() {
      return /iPhone/i.test(navigator.userAgent);
    },
    prev() {
      return '<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer">\n' +
        '<path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 12.5C22.5 9.71523 21.3938 7.04451 19.4246 5.07538C17.4555 3.10625 14.7848 2 12 2C9.21523 2 6.54451 3.10625 4.57538 5.07538C2.60625 7.04451 1.5 9.71523 1.5 12.5C1.5 15.2848 2.60625 17.9555 4.57538 19.9246C6.54451 21.8938 9.21523 23 12 23C14.7848 23 17.4555 21.8938 19.4246 19.9246C21.3938 17.9555 22.5 15.2848 22.5 12.5ZM1.04907e-06 12.5C1.3273e-06 9.3174 1.26428 6.26515 3.51472 4.01472C5.76516 1.76428 8.8174 0.499999 12 0.499999C15.1826 0.499999 18.2348 1.76428 20.4853 4.01472C22.7357 6.26515 24 9.3174 24 12.5C24 15.6826 22.7357 18.7348 20.4853 20.9853C18.2348 23.2357 15.1826 24.5 12 24.5C8.8174 24.5 5.76515 23.2357 3.51472 20.9853C1.26428 18.7348 7.70842e-07 15.6826 1.04907e-06 12.5ZM17.25 13.25C17.4489 13.25 17.6397 13.171 17.7803 13.0303C17.921 12.8897 18 12.6989 18 12.5C18 12.3011 17.921 12.1103 17.7803 11.9697C17.6397 11.829 17.4489 11.75 17.25 11.75L8.5605 11.75L11.781 8.531C11.8507 8.46127 11.906 8.37848 11.9438 8.28738C11.9815 8.19627 12.0009 8.09861 12.0009 8C12.0009 7.90138 11.9815 7.80373 11.9438 7.71262C11.906 7.62151 11.8507 7.53873 11.781 7.469C11.7113 7.39927 11.6285 7.34395 11.5374 7.30621C11.4463 7.26848 11.3486 7.24905 11.25 7.24905C11.1514 7.24905 11.0537 7.26848 10.9626 7.30621C10.8715 7.34395 10.7887 7.39927 10.719 7.469L6.219 11.969C6.14916 12.0387 6.09374 12.1214 6.05593 12.2125C6.01812 12.3037 5.99866 12.4013 5.99866 12.5C5.99866 12.5986 6.01812 12.6963 6.05593 12.7874C6.09374 12.8786 6.14916 12.9613 6.219 13.031L10.719 17.531C10.7887 17.6007 10.8715 17.656 10.9626 17.6938C11.0537 17.7315 11.1514 17.7509 11.25 17.7509C11.3486 17.7509 11.4463 17.7315 11.5374 17.6938C11.6285 17.656 11.7113 17.6007 11.781 17.531C11.8507 17.4613 11.906 17.3785 11.9438 17.2874C11.9815 17.1963 12.0009 17.0986 12.0009 17C12.0009 16.9014 11.9815 16.8037 11.9438 16.7126C11.906 16.6215 11.8507 16.5387 11.781 16.469L8.5605 13.25L17.25 13.25Z" fill="#FEFEFE"/>\n' +
        '</svg>'
    },
    next() {
      return '<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer">\n' +
        '        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 12.5C1.5 15.2848 2.60625 17.9555 4.57538 19.9246C6.54451 21.8938 9.21523 23 12 23C14.7848 23 17.4555 21.8938 19.4246 19.9246C21.3938 17.9555 22.5 15.2848 22.5 12.5C22.5 9.71523 21.3938 7.04451 19.4246 5.07538C17.4555 3.10625 14.7848 2 12 2C9.21523 2 6.54451 3.10625 4.57538 5.07538C2.60625 7.04451 1.5 9.71523 1.5 12.5ZM24 12.5C24 15.6826 22.7357 18.7348 20.4853 20.9853C18.2348 23.2357 15.1826 24.5 12 24.5C8.8174 24.5 5.76516 23.2357 3.51472 20.9853C1.26428 18.7348 0 15.6826 0 12.5C0 9.3174 1.26428 6.26516 3.51472 4.01472C5.76516 1.76428 8.8174 0.5 12 0.5C15.1826 0.5 18.2348 1.76428 20.4853 4.01472C22.7357 6.26516 24 9.3174 24 12.5ZM6.75 11.75C6.55109 11.75 6.36032 11.829 6.21967 11.9697C6.07902 12.1103 6 12.3011 6 12.5C6 12.6989 6.07902 12.8897 6.21967 13.0303C6.36032 13.171 6.55109 13.25 6.75 13.25L15.4395 13.25L12.219 16.469C12.1493 16.5387 12.094 16.6215 12.0562 16.7126C12.0185 16.8037 11.9991 16.9014 11.9991 17C11.9991 17.0986 12.0185 17.1963 12.0562 17.2874C12.094 17.3785 12.1493 17.4613 12.219 17.531C12.2887 17.6007 12.3715 17.656 12.4626 17.6938C12.5537 17.7315 12.6514 17.7509 12.75 17.7509C12.8486 17.7509 12.9463 17.7315 13.0374 17.6938C13.1285 17.656 13.2113 17.6007 13.281 17.531L17.781 13.031C17.8508 12.9613 17.9063 12.8786 17.9441 12.7874C17.9819 12.6963 18.0013 12.5987 18.0013 12.5C18.0013 12.4013 17.9819 12.3037 17.9441 12.2125C17.9063 12.1214 17.8508 12.0387 17.781 11.969L13.281 7.469C13.2113 7.39927 13.1285 7.34395 13.0374 7.30621C12.9463 7.26848 12.8486 7.24905 12.75 7.24905C12.6514 7.24905 12.5537 7.26848 12.4626 7.30621C12.3715 7.34395 12.2887 7.39927 12.219 7.469C12.1493 7.53873 12.094 7.62152 12.0562 7.71262C12.0185 7.80373 11.9991 7.90138 11.9991 8C11.9991 8.09862 12.0185 8.19627 12.0562 8.28738C12.094 8.37848 12.1493 8.46127 12.219 8.531L15.4395 11.75L6.75 11.75Z" fill="#FEFEFE"/>\n' +
        '      </svg>'
    }
  },
  data() {
    return {
      services: [
        {
          icon: 'service.svg',
          title: "ОБЩЕСТРОИТЕЛЬНЫЕ УСЛУГИ И СМР",
          text: "Мы строим жилые, административные и промышленные здания, здания культуры и образования, сельхозназначения, здания относящиеся к объектам инфраструктуры, а так же автомобильные и железные дороги, автомобильные развязки, подземные инженерные сети и коммуникации, объекты энергетики, металлургии, нефте-газового комплекса, объекты социального назначения."
        },
        {
          icon: 'electro.svg',
          title: "ЭЛЕКТРОМОНТАЖНЫЕ РАБОТЫ",
          text: "Мы строим жилые, административные и промышленные здания, здания культуры и образования, сельхозназначения, здания относящиеся к объектам инфраструктуры, а так же автомобильные и железные дороги, автомобильные развязки, подземные инженерные сети и коммуникации, объекты энергетики, металлургии, нефте-газового комплекса, объекты социального назначения."
        },
        {
          icon: 'ShieldCheck.svg',
          title: "СОЗДАНИЕ ИНТЕГРИРОВАННЫХ СИСТЕМ (ВИДЕО, ОХРАНЫ, КОНТРОЛЯ ДОСТУПА, ПОЖАРНОЙ СИГНАЛИЗАЦИИ)",
          text: "Мы строим жилые, административные и промышленные здания, здания культуры и образования, сельхозназначения, здания относящиеся к объектам инфраструктуры, а так же автомобильные и железные дороги, автомобильные развязки, подземные инженерные сети и коммуникации, объекты энергетики, металлургии, нефте-газового комплекса, объекты социального назначения."
        },
        {
          icon: 'roof.png',
          title: "КРОВЕЛЬНЫЕ РАБОТЫ",
          text: "Мы строим жилые, административные и промышленные здания, здания культуры и образования, сельхозназначения, здания относящиеся к объектам инфраструктуры, а так же автомобильные и железные дороги, автомобильные развязки, подземные инженерные сети и коммуникации, объекты энергетики, металлургии, нефте-газового комплекса, объекты социального назначения."
        },
        {
          icon: 'pantone.png',
          title: "ВНУТРЕННИЕ И НАРУЖНЫЕ ОТДЕЛОЧНЫЕ РАБОТЫ",
          text: "Мы строим жилые, административные и промышленные здания, здания культуры и образования, сельхозназначения, здания относящиеся к объектам инфраструктуры, а так же автомобильные и железные дороги, автомобильные развязки, подземные инженерные сети и коммуникации, объекты энергетики, металлургии, нефте-газового комплекса, объекты социального назначения."
        },
        {
          icon: 'building-works.svg',
          title: "СТРОИТЕЛЬСТВО ВНУТРЕННИХ И ВНЕШНИХ ИНЖЕНЕРНЫХ СЕТЕЙ",
          text: "Мы строим жилые, административные и промышленные здания, здания культуры и образования, сельхозназначения, здания относящиеся к объектам инфраструктуры, а так же автомобильные и железные дороги, автомобильные развязки, подземные инженерные сети и коммуникации, объекты энергетики, металлургии, нефте-газового комплекса, объекты социального назначения."
        },
        {
          icon: 'heat.png',
          title: "ОТОПЛЕНИЕ, ВЕНТИЛЯЦИЯ И КОНДИЦИОНИРОВАНИЕ",
          text: "Мы строим жилые, административные и промышленные здания, здания культуры и образования, сельхозназначения, здания относящиеся к объектам инфраструктуры, а так же автомобильные и железные дороги, автомобильные развязки, подземные инженерные сети и коммуникации, объекты энергетики, металлургии, нефте-газового комплекса, объекты социального назначения."
        },
        {
          icon: 'supply.svg',
          title: "ПОСТАВКА МАТЕРИАЛОВ И ОБОРУДОВАНИЯ",
          text: "Мы строим жилые, административные и промышленные здания, здания культуры и образования, сельхозназначения, здания относящиеся к объектам инфраструктуры, а так же автомобильные и железные дороги, автомобильные развязки, подземные инженерные сети и коммуникации, объекты энергетики, металлургии, нефте-газового комплекса, объекты социального назначения."
        },
        {
          icon: 'laptop.svg',
          title: "ПОСТАВКА И ИМПЛЕМЕНТАЦИЯ IT‑ОБОРУДОВАНИЯ DELL",
          text: "Комплексные поставки оборудования и программного обеспечения поставка серверов, рабочих станций и тонких клиентов, коммутационного оборудования, систем хранения данных и систем бесперебойного питания, стойки, шкафы, поставка и внедрение программного обеспечения."
        }
      ],
      active_el: 0,
      partners: [
        {
          id: 1,
          pic: 'image 10.png'
        },
        {
          id: 2,
          pic: 'image 11.png'
        },
        {
          id: 3,
          pic: 'image 13.png'
        },
        {
          id: 4,
          pic: 'image 14.png'
        },
        {
          id: 5,
          pic: 'image 15.png'
        },
        {
          id: 6,
          pic: 'image 16.png'
        }
      ],
      appstoreLink: appstore_link,
      googlePlayLink: google_play_link,
    }
  },
  methods: {
    activate(index) {
      this.active_el = index
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
}

.heading {
  color: #3A3A3A;
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 41px;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on;
  position: relative;

  //white-space: nowrap;
  strong {
    color: #49BA80;
  }
}

.heading-mobile {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-top: 48px;
}

.home-container {}

.home-content {
  margin: 40px 0;
  max-width: 1110px !important;
  width: 100%;
}

.left-col {}

.right-col {
  background-image: url("../assets/clever_big.svg");
  background-repeat: no-repeat;
  background-attachment: local;
  background-position: top 0 left 35px;
  background-size: auto 600px;

  &__mobile {
    background-size: auto 419px;
    background-position: top 0 left 80px;
  }
}

.parent {
  position: relative;
  top: 0;
  left: 0;
}

.clever-bg-container {
  background-color: #c9c9c9;
  width: auto;
  height: 693px;
  position: absolute;
  top: 3%;
  left: 55%;

  &__mobile {
    top: 15%;
    left: 35%;
  }
}

.clever-bg {
  height: auto;
  width: 620px;
  object-fit: cover;
}

.mobile-phone {
  position: absolute;
  top: 78px;
  left: 75px;
}

.h2 {
  font-size: 40px;
  font-weight: 400;
  color: #D8B983;

  &__sign {
    font-weight: 700;
  }
}

.see-more span {
  text-decoration: underline;
  color: #D8B983;
  font-size: 16px;
}

.square {
  left: -20px;
  top: 39px;
  position: absolute;
  width: 440px;
  height: 249px;
  border: 1px #D8B983 solid;
  z-index: -2;
}

.bordered-block {
  border: 1px #D8B983 solid;
  padding: 20px;

  &__small {
    height: 100%;
    position: relative;
    padding: 20px 13px;
  }

  &__header {
    position: absolute;
    top: -12px;
    left: 18px;
    color: #ffffff;
    background-color: #000;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.bordered-block ul {
  list-style-type: none;
  padding-left: 0;
}

.bordered-block ul li {
  padding: 16px 0;
  border-bottom: 1px #D8B983 solid;
  color: #FEFEFE;
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
    padding-bottom: 0;
  }

  &.active {
    color: #D3B98A;
    font-weight: 700;
  }
}

.third {
  margin-top: 60px;
  margin-bottom: 40px;
  flex: 0 1 350px
}

.top-icon {
  position: absolute;
  top: -60px;
}

.partner-block {
  flex: 1 1 16.666%;
}

#contacts {
  margin-bottom: 0;
}

.contact-block {
  position: absolute;
  top: 40%;
  right: 15%;
  width: 280px;
  padding: 16px;
  background: black;
  color: #ffffff;
  z-index: 9999;

  ul {
    list-style-type: none;
    padding-left: 0;

    li span {
      padding-left: 10px;
      color: #FFFFFF;
      font-weight: 400;
      font-size: 16px;
      line-height: 35px;
    }
  }

  &__icon {
    font-size: 20px;
  }
}

::v-deep .VueCarousel-navigation-button {
  @media (max-width: 375px) {
    bottom: -55px;
    top: unset;
  }

  &:focus {
    outline: none;
  }
}

::v-deep .VueCarousel button.VueCarousel-navigation-next {
  @media (max-width: 375px) {
    right: 50%;
  }
}

::v-deep .VueCarousel button.VueCarousel-navigation-prev {
  @media (max-width: 375px) {
    left: 50%;
  }
}
</style>
