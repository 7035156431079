import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import vuetify from './plugins/vuetify'
import Home from "@/components/Home";
import VueCarousel from 'vue-carousel';
Vue.use(VueRouter);
Vue.use(VueCarousel);

const routes = [
	{
		path: "/",
		component: Home,
	},
];

const router = new VueRouter({
	routes,
	mode: "history",
});


Vue.config.productionTip = false

new Vue({
	router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
