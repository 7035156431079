<template>
  <v-navigation-drawer
      v-model="drawer"
      absolute
      dark
      app
      temporary
      width="290"
      class="nav-drawer"
      color="rgba(57, 112, 96, 0.7)"
  >
    <v-list>
      <v-list-item
          v-for="item in links"
          :key="item.url"
          link
          :to="item.url"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <a :href="isIPhone ? appstoreLink : googlePlayLink">
          <v-img
              alt="Доступно для скачинвания"
              class="shrink mx-auto mb-4"
              :src="require(`../assets/${isIPhone ? 'appstore' : 'googleplay'}-white.svg`)"
              contain
              transition="scale-transition"
              height="32"
          />
        </a>
        <v-row no-gutters justify="center" height="26">
          <v-img
              v-for="(ic, index) in socialIcons"
              :key="ic.title"
              :alt="ic.title"
              class="shrink"
              :class="index === 1 && 'mx-2'"
              style="filter: invert(1)"
              :src="require('../assets/icons/' + ic.src)"
              contain
          />
        </v-row>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import {appstore_link, google_play_link} from "@/helper";

export default {
  name: "Drawer",
  data: () => ({
    drawer: false,
    appstoreLink: appstore_link,
    googlePlayLink: google_play_link,
    links: [
      {
        title: 'О нас',
        url: '/about'
      },
      {
        title: 'Партнерам',
        url: '/info'
      },
      {
        title: 'Вопросы и ответы',
        url: '/faq'
      },
      {
        title: 'Напишите нам',
        url: '/feedback'
      },
    ],
    socialIcons: [
      {
        title: 'Instagram',
        src: 'ig.svg',
      },{
        title: 'Facebook',
        src: 'fb.svg',
      },{
        title: 'Youtube',
        src: 'yt.svg',
      },
    ]
  }),
  computed: {
    isIPhone() {
      // const userAgent = this.req.headers['user-agent']
      console.log(/mobile/i.test(navigator.userAgent))
      return /iPhone/i.test(navigator.userAgent);
    }
  },
}
</script>

<style scoped>
.nav-drawer {
  backdrop-filter: blur(10px);
}
</style>
