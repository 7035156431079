<template>
  <v-footer color="black" >
    <v-row class="bottom-bar px-4 pb-2 ma-auto"
           no-gutters
           align="center"
           justify="space-between"
    >
      <v-col cols="12" md="3" align="center" class="pa-2">
        <span class="footer-text text-uppercase">© {{ new Date().getFullYear() }} QSM Engineering</span>
      </v-col>

      <v-col
          cols="12"
          md="3"
          align="center">
        <v-img
            alt="Доступно в Appstore"
            class="shrink mr-2"
            :src="require('../assets/qsm.svg')"
            transition="scale-transition"
            max-height="56px"
            contain
        />
      </v-col>
      <v-col cols="12" md="3" class="pa-2">
        <v-row no-gutters justify="center" height="26">
          <v-btn icon>
            <v-icon color="#FEFEFE">mdi-instagram</v-icon>
          </v-btn>
        </v-row>
      </v-col>

    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "BottomBar",
  data: () => ({
    socialIcons: [
      {
        title: 'Instagram',
        src: 'ig.svg',
      },{
        title: 'Facebook',
        src: 'fb.svg',
      },{
        title: 'Youtube',
        src: 'yt.svg',
      },
    ]
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }
  },
}

</script>

<style scoped>

.bottom-bar {
  max-width: 1110px;
}
.footer-text{
  color: #FEFEFE;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  font-feature-settings: 'pnum' on, 'lnum' on;
}
</style>
