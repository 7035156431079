<template>
  <v-app style="background-color: black;">
    <top-bar @toggle-drawer="$refs.drawer.drawer = !$refs.drawer.drawer" />
    <drawer ref="drawer" />
    <v-main>
      <router-view />
    </v-main>
    <bottom-bar />
  </v-app>
</template>

<script>
// import Home from './components/Home';
import TopBar from "./components/TopBar";
import BottomBar from "./components/BottomBar";
import Drawer from "./components/Drawer";

export default {
  name: 'App',
  components: {
    BottomBar,
    // Home,
    TopBar,
    Drawer
  },
  data: () => ({
    drawer: false,
  }),

};
</script>
<style>
html {
  scroll-behavior: smooth !important;
}
a{
  transition: all 150ms ease-in-out;
}
a:hover, a:focus{
  opacity: 0.8;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'),
  url('./assets/fonts/Montserrat-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
* {
  font-family: 'Montserrat', sans-serif;
}
</style>
