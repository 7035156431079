export const about_company = " - это интернет-магазин, где Вы можете найти все виды товаров в одном месте.\n\n Мы казахстанская команда инициативных ребят, которые начали этот проект в конце 2020 года. Это площадка, где мы объединяем покупателей с производителями со всего Казахстана, ближнего и дальнего зарубежья.";
export const company_motto = "Предвосхищать ожидания, осуществлять ваше решение за один день, дарить счастье и успех";
export const mission = "Мы видим своей миссией создать такую экосистему, которая позволит людям покупать товары со всего мира по более доступным ценам, а локальным производителям налаживать сбыт и коммуникацию с клиентами. Мы видим будущее более человечным, в котором увлеченные производители могут легко и просто коммуницировать с людьми, а люди - покупать все необходимое, не беспокоясь о качестве или ценах. Мы хотим соединять людей не просто с бизнесом, а с живыми людьми, членами нашей команды и производителями.";
export const buyers = "Минимальная цена на конечного потребителя, доступ к товарам от живых людей и продавцов, покупка всего в одном месте, быстрая доставка и единая система";
export const service = "Собственная логистическая система, удобные способы работы с продавцами, сервисы: реферальная программа, подписки, B2B сервисные предложения";
export const suppliers = "Аутсорсинг дистрибуции, канал продаж и эквайринга, развитие бренда, инструменты управления продажами и стоком";
export const cost_quality = "Для потребителя — сотрудничество с прямыми поставщиками позволяет добиться максимально комфортных цен и гарантировать ожидаемое качество. Для поставщиков — прозрачность и удобство финансовых отношений с нами и удобство работы с экосистемой.";
export const speed_service = "Для потребителя — скорость доставки за счет отлаженной логистической системы и качество сервиса. Для поставщиков — скорость и безшовность взаимоотношений со складской и логистической системой.";
export const simplicity_humanity = "Для потребителя — простота взаимодействия с экосистемой, человечность коммуникации и восприятия. Для поставщиков — простота вариаций взаимодействия с нами, человечность всего опыта.";

export const appstore_link = "https://apps.apple.com/kz/app/clever-market/id1578284033";
export const google_play_link = "https://play.google.com/store/apps/details?id=ip.partners.clevermarket";
